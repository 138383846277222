<template>
  <b-card class="mb-4 border-0">
    <b-spinner v-if="loading"></b-spinner>

    <template #header>
      <strong>Create configuration</strong>
    </template>

  <div v-if="!loading">
    <div v-for="(config, index) in currentConfigs" :key="index" style="width: 480px;">
        <DynamicInput v-if="computerVisionKeysConfig.includes(config.key)"
        :type="config.type"
        v-model="config.value"
        :inputKey="config.key"
        :label="config.label">
      </DynamicInput>
    </div>
  </div>

    <FloatingButtons :buttons="buttons" v-on:floating-button-click="handleFloatingButtonClick"></FloatingButtons>
  </b-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import DynamicInput from '@/components/DynamicInput.vue';
import FloatingButtons from '@/components/FloatingButtons.vue';


export default {
  name: 'computer-vision-configs',
  data() {
    return {
      loading: false,
      currentConfigs: {},
      computerVisionKeysConfig: [
        'lower_limb_computer_vision_enabled',
        'lower_limb_optical_kit_population',
        'neck_optical_kit_population',
        'upper_limb_optical_kit_population',
        'wrist_hand_optical_kit_population',
        'low_back_optical_kit_population',
      ],
    };
  },
  components: {
    DynamicInput,
    FloatingButtons,
  },
  computed: {
    ...mapState('Config', [ 'changedConfigs', 'numberOfChanges' ]),
    buttons() {
      if (this.numberOfChanges < 1) {
        return {};
      }
      let saveText = 'Save';
      saveText += ` (${this.numberOfChanges})`;
      return {
        cancel: {
          icon: 'x-circle',
          text: 'Revert Changes',
          color: 'danger',
        },
        save: {
          icon: 'save',
          text: saveText,
          color: 'primary',
        },
      };
    },
  },
  watch: {
    currentConfigs: {
      handler(values) {
        this.$store.commit('Config/addConfigChange', values);
      },
      deep: true,
    },
  },
  methods: {
    ...mapGetters('Config', [ 'getCurrentConfigs' ]),
    fillConfigs() {
      const that = this;
      this.loading = true;
      this.$store.dispatch('Config/fetchConfigs').then(() => {
        that.currentConfigs = that.getCurrentConfigs();
        this.loading = false;
      });
    },
    handleFloatingButtonClick(event) {
      if (event === 'cancel') {
        this.fillConfigs();
        return;
      }
      this.$store.dispatch('Config/saveConfigs');
    },
  },
  created() {
    this.fillConfigs();
  },
};
</script>
