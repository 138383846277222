<template>
  <div class="p-3">
    <header class="mb-4">
      <h3>Computer Vision Configs</h3>
    </header>
    <cv-configs></cv-configs>
  </div>
</template>

<script>
import ComputerVisionConfigs from '../../../components/OrderManagement/ComputerVisionConfigs/index.vue';

export default {
  name: 'ComputerVisionConfigs.vue',
  components: {
    'cv-configs': ComputerVisionConfigs,
  },
};
</script>

<style lang="scss">
  @import '../../../styles/OrderManagement.scss';
</style>
